<template>
  <div class="product-item" style="cursor: pointer">
    <div class="product-item--body">
      <div @click.prevent="toDetail(product.id, product.warehouse_id)" class="product-item--image d-flex justify-content-center align-items-center">
        <!-- hide promo sementara -->
        <!-- <div class="product-promo">Promo</div> -->
        <img :src="`${product.showimg_url}?cm-pad_resize,bg-FFFFFF`" style="object-fit: contain;"/>
      </div>

      <a-row class="product-item--content mt-3">
        <a-col
          :span="24"
          @click.prevent="toDetail(product.id, product.warehouse_id)"
          class="title title-item"
        >
          {{ productTitle }}
        </a-col>

        <a-col
          :span="24"
          @click.prevent="toDetail(product.id, product.warehouse_id)"
          style="height: 25px"
          class="mt-1"
          v-if="productVarian"
        >
          <div v-if="option" class="variant-option">
             {{ productVarian }}
          </div>
        </a-col>

        <div v-for="(price, index) in getPrices" :key="index">
          <a-col :span="20" class="price" v-if="index === 0">
            <div class="d-flex align-items-center">
              <WalletOutlined color="var(--biz-brand-button)"/>
              <div class="ml-2">{{ price && toCurrency(price.selling_price) }} {{ price && `/ ${price.uom}` }}</div>
            </div>
          </a-col>
        </div>

        <a-col
          :span="24"
          class="mt-3 d-flex justify-content-center align-items-center buttonShop"
          @click.prevent="() => controlModalAddToCart(true)"
        >
          <div class="d-flex justify-content-center align-items-center buttonShop--cart"> + {{ $t('purchase.catalogue.cart') }} </div>
        </a-col>
      </a-row>
    </div>

    <ModalAddToCart
      :isLoyalty="true"
      :visible="visibleModalAddToCart"
      :product="list_product"
      :selected_product="product"
      :price="prices"
      @controlModalAddToCart="controlModalAddToCart"
    />
  </div>
</template>

<script>
/* eslint-disable */
import ModalAddToCart from '@/components/Purchase/ModalAddToCart.vue'
import WalletOutlined from '@/components/Icons/WalletOutlined.vue'

export default {
  components: {
    ModalAddToCart,
    WalletOutlined,
  },
  props: {
    product: {
      type: Object,
    },
    prices: {
      type: Array,
      default: () => [],
    },
    list_product: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {
        color: '',
        size: '',
        flavour: '',
      },
      unit: null,
      price: {},
      discountText: '',
      visibleModalAddToCart: false,
      option: null,
    }
  },
  methods: {
    controlModalAddToCart(value) {
      this.visibleModalAddToCart = value
    },
    toDetail (product_id, warehouse_id) {
      return this.$router.push({ path: `/loyalty-redeem/catalogue/${product_id}`, query: {warehouse_id: warehouse_id} })
    },
    base_price (price) {
      if (!this.discount) {
        return null
      }
      
      return price / ((100 - Number(this.discount)) / 100)
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
  },
  computed: {
    /**
     * @returns { string | null }
     */
    productTitle () {
      if (!this.product) return null

      // return this.product.title
      if (this.product.title.length <= 43) {
        return this.product.title
      }

      const string = this.product.title
      const maxLength = 45

      let trimmedString = string.substr(0, maxLength)

      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

      return `${trimmedString} . . .`
    },
    productVarian () {
      if (!this.option) return null
      if (this.option.length <= 60) {
        return this.option
      }

      const string = this.option
      const maxLength = 68

      let trimmedString = string.substr(0, maxLength)

      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))

      return `${trimmedString} ....`
    },
    /**
     * @returns { any[] }
     */
    getPrices () {
      if (!this.product || !this.prices) {
        return 0
      }

      const prices = this.prices.find(item => item.product_id === this.product.id)
      return prices ? prices.catalogs[0].price_list : []
    },
    discount () {
      const prices = this.prices.find(item => item.product_id === this.product.id)

      return prices?.discount ? prices.discount : null
    },
  },
  watch: {
    product: {
      immediate: true,
      handler: function (value) {
        const title = []
        if (value && Array.isArray(value.catalogs) && value.catalogs.length > 1) {
          value.catalogs.forEach((item) => {
            if (item.option) {
              
              for (const property in item.option) {
                title.push(item.option[property])
              }
            }
          })
        }
        this.option = title.length ? title.join(' - ') : null
      },
    },
  },
}
</script>

<style lang="scss" scoped>

.product-item {
  margin-bottom: 1.5rem;
  border: 1px solid #e4e9f0;
  padding: .75rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 1.06386px 8.51087px rgba(0, 0, 0, 0.25);
  border-radius: 6.38315px;
  height: auto;

  &--image {
    img {
      width: 209.01px;
      height: 196.54px;

      @media (max-width: 567px) {
        width: 119px;
        height: 119px;
      }
    }

  }

  &--body {
    width: 100%;
    @media (min-width: 576px) {
      width: auto;
      flex-grow: 1;
    }
  }

  &--footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
    justify-content: flex-end;

    @media (min-width: 576px) {
      max-width: 170px;
    }
  }

  &--content {
    flex-grow: 1;
    width: auto;

    .title {
      max-height: 75px !important;
      color: #000000;
    }

    .variant-option {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      margin-top: -10px;
      color: #000000;
    }

    .buttonShop {
      cursor: pointer;
      margin-bottom: 14px;

      &--cart {
        color: var(--biz-brand-button);
        width: 100%;
        height: 40px;
        left: 23px;
        top: 339px;

        border: 2px solid var(--biz-brand-button);
        box-sizing: border-box;
        border-radius: 12px;
        background: #FFFFFF;
        &:hover {
          background: var(--biz-brand-button);
          color: #FFFFFF;
        }
      }

      &--checkout {
        color: var(--biz-brand-button);
        border: 1px solid #015289;
        background: #015289;
        color: #FFFFFF;
        width: 80px;
        height: 33px;
        border-radius: 5px;
      }
    }
  }

  > div:nth-child(0) {
    width: 100%;
  }

  .title-item {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    height: 75px;
    margin-bottom: .25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
    -webkit-box-orient: vertical;
  }

  .description {
    font-size: 85%;
    color: #a7a9c9;
    margin-bottom: .5rem;
  }

  .sku {
    font-size: 85%;
    color: #a7a9c9;
    margin-bottom: .5rem;
  }

  .price {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    text-align: left;
    margin-top: 10px;

    &.slash {
      color: #a7a9c9;
      font-size: .9rem;
      text-decoration-line: line-through;
      margin-bottom: .1rem;
    }

    &.disc {
      color: #a7a9c9;
      font-size: .7rem;
      // margin-bottom: -.5rem;
      margin-bottom: .1rem;
      text-decoration: line-through;
    }
  }
  .discount-tag {
    position: absolute;
    top: -5px;
    right: 7px;
    background: #fa360a;
    color: #fff;
    width: 35px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.25;
    padding: 8px 0;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 0px solid transparent;
      border-right: 3px solid #b3032a;
      position: absolute;
      top: 1px;
      left: 0;
      transform: translate(-100%);
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 14px solid transparent;
      border-top: 8px solid #fa360a;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%);
    }
  }
  .product-promo {
    position: absolute;
    background: #FF0000;
    color: white;
    padding: 2px 10px;
    border-radius: 8px;
    font-size: 12px;
    top: 12px;
    right: 12px;
  }
}

</style>
